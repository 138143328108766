import React from "react";
import styled, { css } from "styled-components";
import Link from "next/link";
import { applyStyleModifiers } from "styled-components-modifiers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";

// Button Modifiers for danger, Success, Block, Mini
const BUTTON_MODIFIER_CONFIG = {
  primary: ({ theme }) => `
		background: ${theme.colors.primary};
		border-color: ${theme.colors.primary};
		:hover {
			background-color: ${theme.colors.lightPrimary}
			border-color: ${theme.colors.lightPrimary}
		}
	`,
  darkPrimary: ({ theme }) => `
		background: ${theme.colors.darkPrimary};
		border-color: ${theme.colors.darkPrimary};
		:hover {
			background-color: ${theme.colors.lightDarkPrimary} !important
			border-color: ${theme.colors.lightDarkPrimary} !important
		}
	`,
  danger: ({ theme }) => `
		background-color: ${theme.colors.danger};
		border-color: ${theme.colors.danger};
		:hover {
			background-color: ${theme.colors.danger} !important;
			border-color: ${theme.colors.danger} !important;
		}
	`,
  white: ({ theme }) => `
		background-color: white;
		border-color: white;
    color: ${theme.colors.primary};
		:hover {
			background-color: #f8fffc !important;
			border-color: #f8fffc !important;
		}
	`,
  disabled: ({ theme }) => `
		background-color: ${theme.colors.disabled};
		border-color: ${theme.colors.disabled};
		cursor: not-allowed !important;
		:hover {
			background-color: ${theme.colors.disabled} !important;
			border-color: ${theme.colors.disabled}
		};
	`,
  block: () => `
    width: 100%;
    display: block;
	`,
  mini: () => `
    cursor: pointer;
    height: 38px;
    width: 100%;
    border-width: 2px;
    font-size: 1.4rem;
    padding: 8px 26px;
	`,
  medium: () => `
    cursor: pointer;
    height: 38px;
    width: 100%;
    border-width: 2px;
    font-size: 1.7rem;
    padding: 10.5px 20px;
    
	`,
  slim: () => `
    cursor: pointer;
    height: 38px;
    min-height: 38px;
    width: 100%;
    line-height: 9px;
    border-width: 2px;
    font-size: 1.5rem;
    padding: 10.5px 20px;
	`,
  transparent: () => `
    background: transparent !important;
	`,
};

// For buttons components only, it can
const BUTTON_ONLY_MODIFER_LINK = {
  mini: () => `
    cursor: pointer;
    height: 38px;
    width: fit-content;
    border-width: 2px;
    min-height: 37px;
    font-size: 1.4rem;
    padding: 0px 26px;
    `,
  superMini: () => `
    cursor: pointer;
    
    width: fit-content;
    border-width: 2px;
    min-height: 37px;
    font-size: 1.4rem;
    padding: 0px 20px;
	`,
};

// Loading icon sytles
const LOADING_ICON_MODIFIER_CONFIG = {
  white: ({ theme }) => `
      color: ${theme.colors.primary};
    `,
  secondary: ({ theme }) => `
      color: ${theme.colors.primary};
    `,
  danger: () => `
      color: white;
    `,
  primary: () => `
      color: white;
    `,
  slim: () => `
      margin-top: -4px;
    `,
  mini: () => `
      margin-bottom: -5px;
    `,
};

// Outline Only secondary button modifiers
const BUTTON_SECONDARY_MODIFIER_CONFIG = {
  secondary: ({ theme }) => `
		background-color: white;
		color: ${theme.colors.primary};
		:hover {
      background-color: white;
			color: ${theme.colors.lightPrimary}
		}
	`,
  dangerSecondary: ({ theme }) => `
		background-color: ${theme.colors.white};
    color: ${theme.colors.danger};
    border-color: ${theme.colors.danger}
		:hover {
			background-color: ${theme.colors.white}
			color: ${theme.colors.lightVersion.danger}
			border-color: ${theme.colors.lightVersion.danger}
		}
	`,
  disabledSecondary: ({ theme }) => `
		background-color: ${theme.colors.white};
		color: ${theme.colors.disabled};
		border-color: ${theme.colors.disabled};
		cursor: not-allowed;
		:hover {
			background-color: ${theme.colors.white}
			color: ${theme.colors.disabled}
			border-color: ${theme.colors.disabled}
		};
	`,
};

// Base button styles
const BaseButtonStyles = css`
  color: #fff;
  cursor: pointer;
  min-height: 48px;
  opacity: 1;
  text-align: center;
  white-space: nowrap;
  font-size: 1.65rem;
  text-transform: uppercase;
  background-color: ${(props) => props.theme.colors.primary};
  border: 3px solid black;
  border-color: ${(props) => props.theme.colors.primary};
  border-radius: 1000px;
  font-family: ${(props) => props.theme.fonts.paragraph};
  padding: 12px 45px;
  transition: all 0.2s ease;
  font-weight: 700;
  letter-spacing: 0.8px;
  :hover {
    background-color: ${(props) => props.theme.colors.lightPrimary};
    border-color: ${(props) => props.theme.colors.lightPrimary};
  }
`;

// For button components only
const StyledButton = styled.button`
  ${BaseButtonStyles}
  ${applyStyleModifiers(BUTTON_MODIFIER_CONFIG)};
  ${applyStyleModifiers(BUTTON_SECONDARY_MODIFIER_CONFIG)};
  ${applyStyleModifiers(BUTTON_ONLY_MODIFER_LINK)};
  min-width: ${(props) => props.minWidth};
  outline: none;
`;

const StyledButtonLink = styled.a`
  ${BaseButtonStyles}
  line-height: 61px;
  text-decoration: none;
  white-space: nowrap;
  ${applyStyleModifiers(BUTTON_MODIFIER_CONFIG)};
  ${applyStyleModifiers(BUTTON_SECONDARY_MODIFIER_CONFIG)};
`;

// Loading icon styles
const LoadingIcon = styled.div`
  height: 22px;
  & svg {
    color: white;
    font-size: 2.4rem !important;
    margin-top: 0px;
    line-height: 32px;
    ${applyStyleModifiers(LOADING_ICON_MODIFIER_CONFIG)};
  }
`;

// Universal Button Components
class Button extends React.Component {
  render() {
    // If the link is to an external site
    if (this.props.externalLink) {
      return (
        <StyledButtonLink
          modifiers={this.props.modifiers}
          href={this.props.href}
          target={this.props.newWindow ? "_blank" : ""}
        >
          {this.props.children}
        </StyledButtonLink>
      );

      // Else if is is an internal next / router link
    } else if (this.props.link) {
      return (
        <Link passHref href={this.props.href} as={this.props.as}>
          <StyledButtonLink modifiers={this.props.modifiers}>
            {this.props.children}
          </StyledButtonLink>
        </Link>
      );

      // Else, just render a plain button
    } else {
      return (
        <StyledButton
          disabled={
            this.props.modifiers === "disabled" ||
            this.props.modifiers.includes("disabled")
          }
          modifiers={this.props.modifiers}
          onClick={this.props.onClick}
          minWidth={this.props.minWidth}
        >
          {this.props.loading ? (
            <LoadingIcon modifiers={this.props.modifiers}>
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            </LoadingIcon>
          ) : (
            this.props.children
          )}
        </StyledButton>
      );
    }
  }
}

export default Button;
